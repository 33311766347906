import React, { useEffect } from "react"
import AOS from "aos"

import NewsItem from "../NewsItem"

import news_list from "../../data/news.json"
import "aos/dist/aos.css"
import "./styles.scss"

function NewsPage() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 800,
      easing: "ease-in-out",
      offset: -100,
    })
  })

  return (
    <div id="news-page">
      <h1 className="title" data-aos="fade-up">
        FABWORK na mídia
      </h1>
      <div className="news-items">
        {news_list.map((new_item, index) => (
          <NewsItem
            key={index}
            link={new_item.link}
            image_url={new_item.image_url}
            date={new_item.date}
            description={new_item.description}
            name={new_item.name}
            duration={new_item.duration}
          />
        ))}
      </div>
    </div>
  )
}

export default NewsPage

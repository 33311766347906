import React from "react"

import "./styles.scss"

function NewsItem({ link, name, description, date, image_url, duration = "" }) {
  const months = [
    "Janeiro ",
    "Fevereiro ",
    "Março ",
    "Abril ",
    "Maio ",
    "Junho ",
    "Julho ",
    "Agosto ",
    "Setembro ",
    "Outubro ",
    "Novembro ",
    "Dezembro ",
  ]
  const dateObj = new Date(date)
  const currentYear = new Date().getFullYear()
  const episodeYear = dateObj.getFullYear()
  const formatedDate =
    dateObj.getDate() +
    " de " +
    months[dateObj.getMonth()] +
    (episodeYear !== currentYear ? episodeYear : "")

  return (
    <div className="news-item">
      <a href={link} className="img-container" target="_blank" rel="noreferrer">
        <img
          className="thumb"
          src={image_url}
          alt={"Thumbnail da notícia " + name}
        />
        {/* <img className="play-button" src={play_button} alt="" /> */}
      </a>
      <div className="text-info">
        <a href={link} target="_blank" rel="noreferrer">
          <h2 className="news-item-name">{name}</h2>
        </a>
        <p className="news-item-description">{description}</p>
        <p className="datatime">
          {formatedDate.trim()} {duration ? " • " + duration : ""}
        </p>
      </div>
    </div>
  )
}

export default NewsItem

import React from "react"
import { Helmet } from "react-helmet"

import RDStationConection from "../utils/RDStationConnection"
import NewsPage from "../components/NewsPage"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function Podcast() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notícias | FABWORK</title>
        <link rel="canonical" href="https://fab.work/news/" />
      </Helmet>
      <Header />
      <NewsPage />
      <Footer />
    </div>
  )
}
